import {
  SUPERADMIN_ALLOW,
  SUPERADMIN_REVOKE,
  COMPANYADMIN_ALLOW,
  COMPANYADMIN_REVOKE,
} from 'src/utils/apollo-mutations';

export class RoleChangeService {
  /* vm is component instance */
  vm: any;

  constructor(vm: any) {
    this.vm = vm;
  }

  public handleRolePermissionChange(item) {
    if (this.vm.isSuperAdminLoggedIn) {
      this._handleRolePermissionChangeForSuperAdminLogin(item);
      return;
    }
    this._handleRolePermissionChangeForCompanyAdminLogin(item);
  }

  private _handleRolePermissionChangeForCompanyAdminLogin(item) {
    /* For company admins new feature*/
    if (item.Role == 'ADMIN') {
      this.revokePermissionCompanyAdmin(item);
      return;
    }
    this.allowPermissionCompanyAdmin(item);
  }

  private async allowPermissionCompanyAdmin(item) {
    try {
      const res = await this.vm.$apollo.mutate({
        mutation: COMPANYADMIN_ALLOW,
        variables: { TeamID: item.TeamID, Email: item.Email },
      });
      this._handleRoleChangeResponse(res.data.allowCompanyAdmin.successMessage);
    } catch (error) {
      this._handleRoleChangeFailure();
    }
  }

  private async revokePermissionCompanyAdmin(item) {
    try {
      const res = await this.vm.$apollo.mutate({
        mutation: COMPANYADMIN_REVOKE,
        variables: { TeamID: item.TeamID, Email: item.Email },
      });
      this._handleRoleChangeResponse(
        res.data.revokeCompanyAdmin.successMessage
      );
    } catch (error) {
      this._handleRoleChangeFailure();
    }
  }

  private async allowPermissionSuperAdmin(item) {
    try {
      const res = await this.vm.$apollo.mutate({
        mutation: SUPERADMIN_ALLOW,
        variables: { TeamID: item.TeamID, Email: item.Email },
      });
      this._handleRoleChangeResponse(res.data.allowSuperAdmin.successMessage);
    } catch (error) {
      this._handleRoleChangeFailure();
    }
  }

  private async revokePermissionSuperAdmin(item) {
    try {
      const res = await this.vm.$apollo.mutate({
        mutation: SUPERADMIN_REVOKE,
        variables: { TeamID: item.TeamID, Email: item.Email },
      });
      this._handleRoleChangeResponse(res.data.revokeSuperAdmin.successMessage);
    } catch (error) {
      this._handleRoleChangeFailure();
    }
  }

  private _handleRoleChangeResponse(message) {
    if (message === 'error') {
      return this._handleRoleChangeFailure();
    }
    this.vm.closeSuperAdminPopup();
    this.vm.msgModel = true;
    this.vm.msgTitle = 'Success';
    this.vm.message = message;
  }

  private _handleRoleChangeFailure() {
    this.vm.closeSuperAdminPopup();
    this.vm.msgModel = true;
    this.vm.msgTitle = 'Error!!!';
    this.vm.message = 'Something Went Wrong.';
  }

  private _handleRolePermissionChangeForSuperAdminLogin(item) {
    if (item.Role == 'SUPERADMIN') {
      this.revokePermissionSuperAdmin(item);
      return;
    }
    this.allowPermissionSuperAdmin(item);
  }
}
