



















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'ActionColumn',
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    isSuperAdminLoggedIn: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    handleRolePermissionChange() {
      if(this.disabled) return;
      this.$emit('handleRolePermissionChange');
    },
    getTooltipTextForActionsColumn(item) {
      if(this.disabled){
        return `Removing the sole admin is prohibited.`
      }
      if (this.isSuperAdminLoggedIn) {
        if (item.Role == 'SUPERADMIN') return `Remove Super-Admin Access`;
        return `Remove Super-Admin Access`;
      }
      if (item.Role === 'STAFF') return `Add Admin Access`;
      if (item.Role === 'ADMIN') return `Remove Admin Access`;
    },
    getIconForActionsColumn(item) {
      if (item.Role == 'SUPERADMIN' || item.Role == 'ADMIN')
        return `mdi-account-minus`;
      return `mdi-account-plus`;
    },
  },
});
