<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container only-heading-part">
        <!-- Provides the application the proper gutter -->
        <div class="top-page-header page-header-with-banner" :lang="locale">
          <div class="head-title top-space">
            <div
              class="top-heading-left"
              :class="syncStatus ? 'syncActive' : ''"
            ></div>
            <div class="top-heading-right"></div>
          </div>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            class="custom-tab fixed-tab"
          >
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <v-container
          fluid
          class="v-main-content stepper-template only-simple-content"
        >
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="3" class="pa-4 rounded-lg extra-padding">
              <v-card-text class="px-0 pt-0 pb-0">
                <div
                  class="sub-heading-with-btn d-flex flex-wrap align-baseline mb-0"
                >
                  <!-- section : Manage members headline-->
                  <h3 class="black-text">
                    {{ $t('teamMgmt.manageUsers') }}
                    <v-tooltip bottom color="primarydark">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="greyxdark--text"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span>{{ $t('teamMgmt.invite') }}</span>
                    </v-tooltip>
                  </h3>

                  <div class="d-flex align-baseline team-header">
                    <!-- section : search input field -->
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('labels.search')"
                      hide-details
                      class="max-275 mr-2"
                    ></v-text-field>

                    <!-- section : Members selection dropdown -->
                    <v-select
                      v-model="selectedMemberType"
                      :items="memberTypes"
                      item-text="text"
                      item-value="value"
                      attach
                      dense
                      outlined
                      return-object
                      @change="changeMemberList"
                      class="mt-3 mr-2 max-275"
                    >
                    </v-select>

                    <!-- section : Add member dialog popup -->
                    <v-dialog
                      v-model="addMemberDialog"
                      width="500px"
                      persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          depressed
                          v-bind="attrs"
                          v-on="on"
                          class="ml-0"
                          ><v-icon>mdi-plus</v-icon
                          >{{ $t('teamMgmt.add') }}</v-btn
                        >
                      </template>
                      <v-card class="pa-0">
                        <v-card-title
                          class="d-flex justify-space-between w-100 primary white--text px-6 py-3"
                        >
                          <h5>{{ $t('teamMgmt.add') }}</h5>
                          <v-icon
                            @click="closeAddMemberPopup()"
                            class="white--text"
                            >mdi-close</v-icon
                          >
                        </v-card-title>
                        <v-card-text class="px-6 py-8">
                          <v-form class="mb-5" v-model="memberValid" ref="form">
                            <v-row class="mt-0">
                              <v-col cols="12" sm="12" class="py-0">
                                <label class="black--text mb-3 d-flex">{{
                                  $t('teamMgmt.email')
                                }}</label>
                                <v-text-field
                                  :placeholder="$t('teamMgmt.email')"
                                  v-model="addmemberDetails.Email"
                                  required
                                  outlined
                                  dense
                                  :rules="emailRules"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mt-3">
                              <v-col
                                cols="12"
                                sm="12"
                                class="py-0 d-flex justify-end w-100 pr-0"
                              >
                                <v-btn
                                  color="primary"
                                  class="mr-3"
                                  @click="checkAndAdd"
                                  depressed
                                  :disabled="!memberValid"
                                  >{{ $t('teamMgmt.add') }}</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
              </v-card-text>

              <div class="dataTable mt-0">
                <div class="d-flex justify-space-between mb-3">
                  <p class="text-para-heading">
                    {{ $t('teamMgmt.allMembers') }}
                  </p>
                </div>
                <!-- section : data table info for the users -->
                <v-data-table
                  :headers="memberheaders"
                  :items="finalMembers"
                  :search="search"
                  :no-data-text="$t('kai.showDataText')"
                  class="table-style-3"
                  :loading="queryLoading"
                >
                  <template v-slot:item.IsDisabled="{ item }">
                    <v-switch
                      v-if="item.InviteStatus === 'ACCEPTED'"
                      color="primary"
                      v-model="item.IsEnabled"
                      inset
                      outlined
                      class="borderedSwitch"
                      @change="changeMemberStatus(item, $event)"
                    ></v-switch>
                  </template>

                  <template v-slot:item.InviteStatus="{ item }">
                    {{ item.InviteStatus }}
                    <v-icon
                      v-if="item.InviteStatus === 'PENDING'"
                      title="Resend Invite"
                      small
                      class="mr-2 icon-hover"
                      @click="reInviteMember(item)"
                      color="primary"
                    >
                      mdi-email-arrow-right-outline
                    </v-icon>
                  </template>
                  <template v-slot:item.CompanyName="{ item }">
                    {{ item.CompanyName }}
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-tooltip bottom max-width="300px" color="primarydark">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2 icon-hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteMember(item)"
                          color="primary"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                  <!-- section : actions column in the table for company admins -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <ActionColumn
                      :item="item"
                      :isSuperAdminLoggedIn="isSuperAdminLoggedIn"
                      :disabled="isThisOnlyOneAdminOrSuperAdminPresentInOrg(item)"
                      @handleRolePermissionChange="
                        handleRolePermissionChange(item)
                      "
                    />
                  </template>

                  <template
                    v-if="
                      loginUserRole == 'ADMIN' || loginUserRole == 'SUPERADMIN'
                    "
                    v-slot:[`item.readOnly`]="{ item }"
                  >
                    <v-switch
                      color="primary"
                      :disabled="isItemAdminOrSuperAdmin(item)"
                      v-model="item.IsReadOnly"
                      inset
                      outlined
                      class="borderedSwitch"
                      @change="changeReadOnyStatus(item, $event)"
                    ></v-switch>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </template>
        </v-container>
      </div>
      <div class="text-center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card>
            <v-card-title
              class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
            >
              <h5>{{ $t('teamMgmt.processing') }}</h5>
            </v-card-title>
            <v-card-text class="d-flex align-start px-6 py-8 w-100">
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <!-- section : Super admin privelege dialog success -->
        <v-dialog v-model="msgModel" persistent width="300">
          <v-card>
            <v-card-title
              class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
            >
              <h5>{{ msgTitle }}</h5>
            </v-card-title>
            <v-card-text
              class="d-flex align-start text-subtitle-1 px-6 pt-4 pb-0 w-100"
            >
              {{ message }}
            </v-card-text>
            <v-card-actions
              class="d-flex justify-end align-center w-100 pb-6 pt-4 px-6"
            >
              <v-btn width=" " color="primary" @click="closeMsgModel(msgTitle)">
                {{ $t('teamMgmt.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <ArchiveStaff
        @onArchiveStaff="onArchiveStaff()"
        :archiveStaffMemberData="archiveStaffMemberData"
      />
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import { LSS } from '@core/services';
import {
  ADD_MEMBER,
  CHANGE_MEMBER_STATUS,
  CHANGE_READONLY_STATUS,
  ReInvite_Member,
} from 'src/utils/apollo-mutations';
import { DELETE_TEAM_MEMBER, MEMBER_DETAILS } from 'src/utils/apollo-queries';
import VueMask from 'v-mask';
import { decodeToken } from '@shared/utils/commom.util';
import ArchiveStaff from 'src/components/team-management/ArchiveStaff.vue';
import {
  SUPERADMIN_ALLOW,
  SUPERADMIN_REVOKE,
  COMPANYADMIN_ALLOW,
  COMPANYADMIN_REVOKE,
} from 'src/utils/apollo-mutations';
import { SignUpService } from '@services/signup.service';
import { BLOCKED_DOMAINS } from 'src/utils/constants';
import { RoleChangeService } from './services/role-change.service';
import ActionColumn from './ActionColumn/ActionColumn.vue';

Vue.use(VueMask);
export default Vue.extend({
  name: 'Settings',
  components: { ArchiveStaff, ActionColumn },
  data() {
    return {
      roleChangeService: null,
      syncStatus: false,
      locale: 'en',
      message: '',
      msgTitle: 'info',
      memStatus: false,
      tab: 0,
      items: [],
      loginUserRole: '',
      search: '',
      email: '',
      password: '',
      checkbox: false,
      inviteDialog: false,
      select: '',
      emailForInvite: '',
      text: 'text',
      addMemberDialog: false,
      msgModel: false,
      loading: false,
      queryLoading: false,
      Role: null,
      finalMembers: [],
      activeMembers: [],
      disabledMembers: [],
      memberDetails: [],
      addmemberDetails: {},
      selectedMemberType: { text: 'All Members', value: 'all' },
      memberTypes: [
        { text: 'All Members', value: 'all' },
        { text: 'Active Members', value: 'active' },
        { text: 'Disabled Members', value: 'disabled' },
      ],
      emails: [
        {
          emailAddress: 'abc@gmail.com',
          updateData: 'Data',
          action: 'Delete',
        },
      ],
      headers2: [
        { text: 'Members', value: 'members' },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
      ],
      allMemberDetailsTable: [
        {
          members: 'Member 1',
          role: 'Data',
          status: 'Open',
          action: 'Delete',
        },
      ],
      isCheckboxTrue: false,
      onClickShow: false,
      memberValid: false,
      archiveStaffMemberData: {},
      blDomains: BLOCKED_DOMAINS,
    };
  },
  computed: {
    isSuperAdminLoggedIn() {
      return this.loginUserRole === 'SUPERADMIN';
    },
    memberheaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('teamMgmt.first'), value: 'FirstName' },
          { text: this.$t('teamMgmt.last'), value: 'LastName' },
          { text: this.$t('teamMgmt.phone'), value: 'Phone' },
          { text: this.$t('teamMgmt.email'), value: 'Email' },
          { text: this.$t('teamMgmt.job'), value: 'JobTitle' },
          { text: this.$t('teamMgmt.role'), value: 'Role' },
          { text: this.$t('teamMgmt.inviteStatus'), value: 'InviteStatus' },
          {
            text: this.$t('teamMgmt.isDisabled'),
            value: 'IsDisabled',
            sortable: false,
          },
          {
            text: 'Delete',
            value: 'delete',
            sortable: false,
          },
          { text: 'Added On', value: 'CreatedOn' },
          { text: 'Actions', value: 'actions' },
          {
            text: 'Read Only',
            value: 'readOnly',
            sortable: false,
          },
        ];
      },
    },
    nameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.fNamereq'),
          (v) =>
            (v && v.length < 13) || this.$t('createCampaignRules.fNameGrt'),
          (v) =>
            !!/^[a-z]*$/gi.test(v) || this.$t('createCampaignRules.fNameValid'),
        ];
      },
    },
    lastNameRules: {
      get() {
        return [
          (v) =>
            (v && v.length) < 13 || this.$t('createCampaignRules.lNameGrt'),
          (v) =>
            /^[a-z]*$/gi.test(v) || this.$t('createCampaignRules.lNameValid'),
        ];
      },
    },
    phoneRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.phoneReq')];
      },
    },
    companyRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.cmpniNameReq')];
      },
    },
    jobRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.jobTitleReq')];
      },
    },
    emailRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.emailReq'),
          (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || this.$t('createCampaignRules.emailValid'),
          (v) => !/\s/.test(v) || 'Email cannot contain spaces',
          (v) =>
            (v &&
              v.includes('@') &&
              !this.blDomains.includes(v.split('@')[1].toLowerCase())) ||
            'Please use an enterprise email account',
        ];
      },
    },
  },
  methods: {
    isThisOnlyOneAdminOrSuperAdminPresentInOrg(item){
      const targetRole = this.isSuperAdminLoggedIn ? "SUPERADMIN" : "ADMIN";
      const admins = this.finalMembers.filter(record => record.Role === targetRole);
      if(admins.length === 1 && item.Email === admins[0].Email){
        return true;
      }
      return false;
    },
    isItemAdminOrSuperAdmin(item){
      return item.Role === "SUPERADMIN" || item.Role === "ADMIN";
    },
    handleRolePermissionChange(item) {
      this.roleChangeService.handleRolePermissionChange(item);
    },
    async getBLDomains() {
      const blDomains = await SignUpService.getBLDomains();
      this.blDomains = blDomains;
    },
    changeMemberList() {
      if (this.selectedMemberType.value == 'all') {
        this.finalMembers = this.memberDetails;
      } else if (this.selectedMemberType.value == 'active') {
        this.finalMembers = this.activeMembers;
      } else if (this.selectedMemberType.value == 'disabled') {
        this.finalMembers = this.disabledMembers;
      }
    },

    allMemberDetails: async function () {
      this.queryLoading = true;
      this.memberDetails = [];
      await this.$apollo
        .query({
          query: MEMBER_DETAILS,
          variables: {},
        })
        .then((res) => {
          this.memberDetails = [];
          const members = res.data.allMemberDetails;
          const currEmail = decodeToken(LSS.token).Email;
          this.memberDetails = members.filter((mem) => mem.Email !== currEmail);
          this.memberDetails = this.memberDetails.sort(
            (a, b) =>
              new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
          );
          const len = this.memberDetails.length;
          for (let index = 0; index < len; index++) {
            const el = this.memberDetails[index];
            this.memberDetails[
              index
            ].CreatedOn = el.CreatedOn.toString().substr(0, 10);
            this.memberDetails[index].IsEnabled = !el.IsDisabled;
          }
          this.queryLoading = false;
          this.finalMembers = this.memberDetails;
          this.activeMembers = this.memberDetails.filter(
            (member) => member.IsDisabled == false
          );
          this.disabledMembers = this.memberDetails.filter(
            (member) => member.IsDisabled == true
          );
        })
        .catch((error) => {
          this.queryLoading = false;
        });
    },
    closeInvitePopup() {
      this.inviteDialog = false;
      this.emailForInvite = null;
    },
    checkAndAdd() {
      const exists = this.memberDetails.some(
        (e) => e.Email == this.addmemberDetails.Email.trim()
      );
      if (!exists) {
        this.addMember();
      } else {
        this.msgModel = true;
        this.msgTitle = 'Error';
        this.message = 'Member with this email already exists in your team.';
      }
    },
    addMember: async function () {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: ADD_MEMBER,
          variables: {
            addmemberDetails: {
              FirstName: '',
              LastName: '',
              Phone: '',
              Email: this.addmemberDetails.Email.trim().toLowerCase(),
              CompanyName: decodeToken(LSS.token).CompanyName,
              JobTitle: '',
            },
          },
        })
        .then((res) => {
          if (res.data.addTeamMember.successMessage == 'Exists in Team') {
            this.loading = false;
            this.closeAddMemberPopup();
            this.msgModel = true;
            this.msgTitle = 'Error!!!';
            this.message =
              'Member with this email already exists in your team.';
          } else {
            this.loading = false;
            this.closeAddMemberPopup();
            this.msgModel = true;
            this.msgTitle = 'Success';
            this.message = 'Member Added.';
          }
        })
        .catch((error) => {
          this.loading = false;
          this.closeAddMemberPopup();
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = 'Something Went Wrong.';
        });
    },
    closeAddMemberPopup() {
      this.addMemberDialog = false;
      this.addmemberDetails = {
        FirstName: null,
        LastName: null,
        Phone: null,
        Email: null,
        CompanyName: null,
        JobTitle: null,
      };
    },
    closeSuperAdminPopup() {
      this.addMemberDialog = false;
    },
    reInviteMember: async function (data) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: ReInvite_Member,
          variables: {
            teamInviteReq: {
              Email: data.Email,
            },
          },
        })
        .then((res) => {
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Success!';
          this.message = 'Invite Link Sent';
        })
        .catch((error) => {
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = 'Something Went Wrong.';
        });
    },
    changeReadOnyStatus: async function (data, event) {
      try {
        await this.$apollo
          .mutate({
            mutation: CHANGE_READONLY_STATUS,
            variables: {
              changeMemberStatusReq: {
                Email: data.Email,
              },
              status: event,
            },
          })
          .then((res) => {
            if (
              res &&
              res.data.changeReadOnlyStatus.successMessage == 'Error'
            ) {
              data.IsReadOnly = !event;
              this.$notify.error('Error while changing member status');
            } else {
              this.$notify.success('Read Only Status Changed');
            }
          })
          .catch((error) => {
            data.IsReadOnly = !event;
            this.$notify.error('Error while changing member status');
          });

        this.allMemberDetails();
      } catch (error) {
        data.IsReadOnly = !event;
      }
    },
    changeMemberStatus: async function (data, event) {
      let IsDisabled;
      if (event == true) {
        IsDisabled = false;
      } else if (event == false) {
        IsDisabled = true;
      }

      try {
        await this.$apollo
          .mutate({
            mutation: CHANGE_MEMBER_STATUS,
            variables: {
              changeMemberStatusReq: {
                Email: data.Email,
              },
              IsDisabled: IsDisabled,
            },
          })
          .then((res) => {
            this.$notify.success('Member Status Changed');
          })
          .catch((error) => {
            this.$notify.error('Error while changing member status');
          });

        this.allMemberDetails();
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMember(memberDetail) {
      const TeamID = [];
      TeamID.push(decodeToken(LSS.token).TeamID);

      this.archiveStaffMemberData = {
        email: memberDetail.Email,
        TeamID: TeamID,
      };
    },

    onArchiveStaff() {
      this.allMemberDetails();
    },

    closeMsgModel(msgTitle) {
      if (msgTitle == 'Success') {
        window.location.reload();
      } else {
        this.msgModel = false;
      }
    },
  },
  mounted() {
    this.getBLDomains();
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    console.log(decodeToken(LSS.token).Role);
    this.loginUserRole = decodeToken(LSS.token).Role;
    this.locale = localStorage.getItem('locale') || 'en';
    this.allMemberDetails();
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
      this.$refs.form.validate();
    });
  },
  created() {
    this.roleChangeService = new RoleChangeService(this);
  },
});
</script>
