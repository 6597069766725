var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "", color: "primarydark" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2 font-22",
                      class: { disabled: _vm.disabled },
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleRolePermissionChange()
                        },
                      },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.getIconForActionsColumn(_vm.item)) + " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.getTooltipTextForActionsColumn(_vm.item)))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }