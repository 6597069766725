var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            { staticClass: "inner-container only-heading-part" },
            [
              _c(
                "div",
                {
                  staticClass: "top-page-header page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c("div", { staticClass: "head-title top-space" }, [
                    _c("div", {
                      staticClass: "top-heading-left",
                      class: _vm.syncStatus ? "syncActive" : "",
                    }),
                    _c("div", { staticClass: "top-heading-right" }),
                  ]),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "custom-tab fixed-tab",
                      attrs: { "background-color": "transparent" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.items, function (item) {
                      return _c("v-tab", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass:
                    "v-main-content stepper-template only-simple-content",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg extra-padding",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c("v-card-text", { staticClass: "px-0 pt-0 pb-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "sub-heading-with-btn d-flex flex-wrap align-baseline mb-0",
                            },
                            [
                              _c(
                                "h3",
                                { staticClass: "black-text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("teamMgmt.manageUsers")) +
                                      " "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primarydark",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "greyxdark--text",
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("teamMgmt.invite"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-baseline team-header",
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "max-275 mr-2",
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: _vm.$t("labels.search"),
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticClass: "mt-3 mr-2 max-275",
                                    attrs: {
                                      items: _vm.memberTypes,
                                      "item-text": "text",
                                      "item-value": "value",
                                      attach: "",
                                      dense: "",
                                      outlined: "",
                                      "return-object": "",
                                    },
                                    on: { change: _vm.changeMemberList },
                                    model: {
                                      value: _vm.selectedMemberType,
                                      callback: function ($$v) {
                                        _vm.selectedMemberType = $$v
                                      },
                                      expression: "selectedMemberType",
                                    },
                                  }),
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: { width: "500px", persistent: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "ml-0",
                                                      attrs: {
                                                        color: "primary",
                                                        depressed: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus"),
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("teamMgmt.add")
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.addMemberDialog,
                                        callback: function ($$v) {
                                          _vm.addMemberDialog = $$v
                                        },
                                        expression: "addMemberDialog",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between w-100 primary white--text px-6 py-3",
                                            },
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("teamMgmt.add"))
                                                ),
                                              ]),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "white--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.closeAddMemberPopup()
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-close")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "px-6 py-8" },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "form",
                                                  staticClass: "mb-5",
                                                  model: {
                                                    value: _vm.memberValid,
                                                    callback: function ($$v) {
                                                      _vm.memberValid = $$v
                                                    },
                                                    expression: "memberValid",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mt-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "black--text mb-3 d-flex",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "teamMgmt.email"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              placeholder: _vm.$t(
                                                                "teamMgmt.email"
                                                              ),
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              rules:
                                                                _vm.emailRules,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addmemberDetails
                                                                  .Email,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.addmemberDetails,
                                                                  "Email",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "addmemberDetails.Email",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mt-3" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "py-0 d-flex justify-end w-100 pr-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-3",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                depressed: "",
                                                                disabled: !_vm.memberValid,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.checkAndAdd,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "teamMgmt.add"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dataTable mt-0" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between mb-3",
                              },
                              [
                                _c("p", { staticClass: "text-para-heading" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("teamMgmt.allMembers")) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-data-table", {
                              staticClass: "table-style-3",
                              attrs: {
                                headers: _vm.memberheaders,
                                items: _vm.finalMembers,
                                search: _vm.search,
                                "no-data-text": _vm.$t("kai.showDataText"),
                                loading: _vm.queryLoading,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.IsDisabled",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        item.InviteStatus === "ACCEPTED"
                                          ? _c("v-switch", {
                                              staticClass: "borderedSwitch",
                                              attrs: {
                                                color: "primary",
                                                inset: "",
                                                outlined: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeMemberStatus(
                                                    item,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.IsEnabled,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "IsEnabled",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.IsEnabled",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.InviteStatus",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.InviteStatus) + " "
                                        ),
                                        item.InviteStatus === "PENDING"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2 icon-hover",
                                                attrs: {
                                                  title: "Resend Invite",
                                                  small: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reInviteMember(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-email-arrow-right-outline "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.CompanyName",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.CompanyName) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.delete",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "max-width": "300px",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-2 icon-hover",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteMember(
                                                                    item
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " mdi-delete-outline "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Delete")])]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.actions",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c("ActionColumn", {
                                          attrs: {
                                            item: item,
                                            isSuperAdminLoggedIn:
                                              _vm.isSuperAdminLoggedIn,
                                            disabled: _vm.isThisOnlyOneAdminOrSuperAdminPresentInOrg(
                                              item
                                            ),
                                          },
                                          on: {
                                            handleRolePermissionChange: function (
                                              $event
                                            ) {
                                              return _vm.handleRolePermissionChange(
                                                item
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  _vm.loginUserRole == "ADMIN" ||
                                  _vm.loginUserRole == "SUPERADMIN"
                                    ? {
                                        key: "item.readOnly",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("v-switch", {
                                              staticClass: "borderedSwitch",
                                              attrs: {
                                                color: "primary",
                                                disabled: _vm.isItemAdminOrSuperAdmin(
                                                  item
                                                ),
                                                inset: "",
                                                outlined: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeReadOnyStatus(
                                                    item,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.IsReadOnly,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "IsReadOnly",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.IsReadOnly",
                                              },
                                            }),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                        },
                        [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.$t("teamMgmt.processing"))),
                          ]),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-start px-6 py-8 w-100" },
                        [
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "300" },
                  model: {
                    value: _vm.msgModel,
                    callback: function ($$v) {
                      _vm.msgModel = $$v
                    },
                    expression: "msgModel",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                        },
                        [_c("h5", [_vm._v(_vm._s(_vm.msgTitle))])]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "d-flex align-start text-subtitle-1 px-6 pt-4 pb-0 w-100",
                        },
                        [_vm._v(" " + _vm._s(_vm.message) + " ")]
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex justify-end align-center w-100 pb-6 pt-4 px-6",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { width: " ", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeMsgModel(_vm.msgTitle)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("teamMgmt.ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ArchiveStaff", {
            attrs: { archiveStaffMemberData: _vm.archiveStaffMemberData },
            on: {
              onArchiveStaff: function ($event) {
                return _vm.onArchiveStaff()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }