


















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DELETE_TEAM_MEMBER } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    archiveStaffMemberData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      teamID: null,
      dialog: false,
      deleting: false,
      email: null
    };
  },
  watch: {
    archiveStaffMemberData(data) {
      this.dialog = true;
      this.teamID = data.TeamID;
      this.email = data.email
    },
  },
  methods: {
    async archiveStaff() {
      this.deleting = true;

      await this.$apollo.query({
        query: DELETE_TEAM_MEMBER,
        variables: {
          email: this.email,
          TeamID : this.teamID
        }
      })
      .then((res) => {
        if(res.data.deleteTeamMember.code === 0) {
          this.$notify.success("Member Deleted");
          this.$emit('onArchiveStaff')
        }
        else if(res.data.deleteTeamMember.code === 7) {
          this.$notify.success("Error while deleting member");
        }
        else if(res.data.deleteTeamMember.code === 9) {
          this.$notify.success("Error while deleting member");
        }
        })
        .catch((error) => {
          this.$notify.error("Something went wrong");
        });
      
      this.deleting = false;
      this.dialog = false;
    },
  },
});
